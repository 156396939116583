import React from "react"
import moment from 'moment-timezone'
import { dataProvider } from "../../App"
import NameCard from "./NameCard"
import QuestionList from "./QuestionList"


moment().tz("Asia/Seoul").format()

class CompUserInfo extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      userId: "",
      profile: {},
      diagnosisScore: [],
      abilityScore: [],
      questionsAbility: [],
      questionDiagnosis: [],
      result: [],

    }
    this.getWaitUserInfo = this.getWaitUserInfo.bind(this)
    this.getResultScore = this.getResultScore.bind(this)
    this.downloadFile = this.downloadFile.bind(this)
  }

  async getWaitUserInfo() {
    try {
      const selectId = this.props.location.pathname.split('/')[2]
      const { data } = await dataProvider('GET_ONE', 'compUsers', { id: selectId })

      if (data) {
        if (data.profile.career === "ready") data.profile.career = '취업준비생'
        else if (data.profile.career === 'junior') data.profile.career = '신입(1~3년)'
        else if (data.profile.career === 'senior') data.profile.career = '경력(3~5년)'
        else if (data.profile.career === 'highSenior') data.profile.career = '경력(5년이상'
        else if (data.profile.career === 'leader') data.profile.career = '팀장(리더) 직급자'
        else if (data.profile.career === 'ceo') data.profile.career = '대표'

        if (data.profile.status === 'ready') data.profile.status = '진단 진행 전'
        else if (data.profile.status === 'wait') data.profile.status = '진단 결과 대기 중'
        else if (data.profile.status === 'progress') data.profile.status = '진단 진행 중'
        else if (data.profile.status === 'complete') data.profile.status = '진단 완료'
      }

      this.setState({
        userId: data.id,
        profile: data.profile,
        diagnosisScore: data.diagnosisScore,
        abilityScore: data.abilityScore,
        questionsAbility: data.questionsAbility,
        questionDiagnosis: data.questionDiagnosis,
      })

    } catch (e) {
      console.log(e)
    }
  }

  async getResultScore() {
    try {
      const selectId = this.props.location.pathname.split('/')[2]
      const { data } = await dataProvider('GET_ONE', 'result', { id: selectId })
      this.setState({
        result: data,
      })

    } catch (e) {
      console.log(e)
    }
  }

  downloadFile = (path, email) => {

    const url = `${process.env.REACT_APP_FILE_URL}/${path}`
    fetch(url, { method: 'GET' })
      .then((res) => {
        return res.blob()
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `진단결과_${email}.pdf`
        document.body.appendChild(a)
        a.click()

      })
      .catch((err) => {
        console.error('err: ', err)
      })
  }


  componentDidMount() {
    this.getWaitUserInfo().then()
    this.getResultScore().then()
  }

  render() {
    const {
      userId,
      profile,
      diagnosisScore,
      abilityScore,
      result,
      questionsAbility,
      questionDiagnosis,

    } = this.state



    return (
      <>
        <div style={{ width: '1300px', padding: '20px' }}>
          <NameCard userId={userId}
            profile={profile}
            diagnosisScore={diagnosisScore}
            abilityScore={abilityScore}
            result={result}
            downloadFile={this.downloadFile}
          />
        </div>

        <div>
          <QuestionList questionsAbility={questionsAbility} questionDiagnosis={questionDiagnosis} profile={profile} />
        </div>


      </>
    )
  }


}
export default CompUserInfo

