import React from "react"
import { BooleanField, DateField, Show, SimpleShowLayout, TextField } from "react-admin"
import { ShowAction } from "../../components/actions"

const UserShow = (props) => {
  return (
    <Show {...props} actions={<ShowAction record={props} />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="email" />
        <TextField source="career" />
        <TextField source="jobName" />
        <BooleanField source="isSwitch" />
        <BooleanField source="isSwitchJob" />
        <BooleanField source="isNotification" />
        <BooleanField source="isMarketing" />
        <TextField source="status" />
        <TextField source="purpose" />
        <DateField source="createdAt" locales="ko" showTime />
      </SimpleShowLayout>
    </Show>
  )
}

export default UserShow 
