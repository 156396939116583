import MenuBook from "@material-ui/icons/MenuBook"
import ContentCreate from "./ContentCreate"
import ContentEdit from "./ContentEdit"
import ContentShow from "./ContentShow"
import ContentList from "./ContentList"

const Contents = {
  list: ContentList,
  create: ContentCreate,
  edit: ContentEdit,
  show: ContentShow,
  icon: MenuBook,
}

export default Contents 
