import { dataProvider } from "../App"

export const authProvider = {
  login: async (params) => {
    const {
      data: { accessToken, refreshToken },
    } = await dataProvider("LOGIN", "auth", { data: params })
    localStorage.setItem("accessToken", accessToken)
    localStorage.setItem("refreshToken", refreshToken)
    return Promise.resolve()
  },
  logout: () => {
    localStorage.removeItem("accessToken")
    localStorage.removeItem("refreshToken")
    return Promise.resolve()
  },
  checkError: (params) => {
    const { status } = params
    if (status === 401 || status === 403) {
      return Promise.reject()
    } else {
      return Promise.resolve()
    }
  },
  checkAuth: (params) => {
    if (params && params.role === "guest") {
      return Promise.resolve()
    } else {
      if (localStorage.getItem("accessToken")) {
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    }
  },
  getPermissions: () => {
    const role = localStorage.getItem("role")
    if (role) {
      return Promise.resolve(role)
    } else {
      return Promise.resolve("guest")
    }
  },
} 
