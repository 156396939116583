import { CREATE, GET_ONE, GET_LIST, DELETE, UPDATE } from "react-admin"

export function request(apiUrl, type, params) {
  let url = apiUrl + "/compUsers"
  let options = {}
  switch (type) {
    case CREATE:
      options.method = "POST"
      options.data = params.data
      break
    case GET_ONE:
      url += `/${params.id}`
      break
    case GET_LIST:
      const { page, perPage } = params.pagination
      const { field, order } = params.sort
      options.params = {
        ...params.filter,
        sort: field,
        order: order,
        start: (page - 1) * perPage,
        perPage: perPage,
      }
      break
    case UPDATE:
      url += `/${params.id}`
      options.method = "PUT"
      options.data = params.data
      break
    case DELETE:
      url += `/${params.id}`
      options.method = "DELETE"
      break
    default:
      break
  }
  return { url, ...options }
}

export function response(res, type, params) {
  let ret = {}
  const { data, status } = res
  switch (type) {
    case GET_LIST:
      if (data) {

        for (let i = 0; i < data.data.length; i++) {

          if (data.data[i].career === "ready") data.data[i].career = '취업준비생'
          else if (data.data[i].career === 'junior') data.data[i].career = '신입(1~3년)'
          else if (data.data[i].career === 'senior') data.data[i].career = '경력(3~5년)'
          else if (data.data[i].career === 'highSenior') data.data[i].career = '경력(5년이상'
          else if (data.data[i].career === 'leader') data.data[i].career = '팀장(리더) 직급자'
          else if (data.data[i].career === 'ceo') data.data[i].career = '대표'

          if (data.data[i].status === 'ready') data.data[i].status = '진단 진행 전'
          else if (data.data[i].status === 'wait') data.data[i].status = '진단 결과 대기 중'
          else if (data.data[i].status === 'progress') data.data[i].status = '진단 진행 중'
          else if (data.data[i].status === 'complete') data.data[i].status = '진단 완료'

        }

        ret = data
      }
      break

    case DELETE:
      if (status === 204) ret = { data: { id: params.id } }
      break
    default:
      ret = { data }
      break
  }
  return ret
}
