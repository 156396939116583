import { withStyles } from "@material-ui/core/styles" 
import React from "react" 
import {
  TopToolbar,
  CreateButton,
  Datagrid,
  DateField,
  List,
  TextField,
} from "react-admin" 

const styles = (theme) => ({
  title: {
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}) 

const Actions = ({ basePath }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} />
  </TopToolbar>
) 

const AdminList = withStyles(styles)(({ classes, ...props }) => {
  return (
    <List
      {...props}
      actions={<Actions />}
      perPage={25}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false} />
        <TextField source="name" sortable={false} />
        <DateField source="createdAt" locales="ko" showTime sortable={false} />
      </Datagrid>
    </List>
  ) 
}) 

export default AdminList 
