import PeopleAltRounded from "@material-ui/icons/PeopleAltRounded"
import UserShow from "./UserShow"
import UserList from "./UserList"

const Users = {
  list: UserList,
  show: UserShow,
  icon: PeopleAltRounded,
}

export default Users 
