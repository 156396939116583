import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CSVLink } from "react-csv"

const useStyles = makeStyles({
    profile: {
        minWidth: 1000,
    },
    TableContainer: {
        width: '1500px',
        border: "1px solid gray",
        height: '500px',
        overflow: 'auto'
    },

    TableHeadRow: {
        borderBottom: "2px solid gray"
    },

})


export default function QuestionList({
    questionsAbility,
    questionDiagnosis,
    profile,
}) {

    let diagnosis = []

    for (let i = 0; i < questionDiagnosis.length; i++) {
        for (let j = 0; j < questionDiagnosis[i].childQuestions.length; j++) {
            diagnosis[(4 * i) + j] = {
                id: (4 * i + j + 1),
                question: questionDiagnosis[i].question,
                answer: questionDiagnosis[i].childQuestions[j].question,
                score: questionDiagnosis[i].childQuestions[j].score,
                ch1Name: questionDiagnosis[i].childQuestions[j].ch1Name,
                ch2Name: questionDiagnosis[i].childQuestions[j].ch2Name,
            }
        }
    }

    const headersAbility = [
        { label: "번호", key: "id" },
        { label: "질문", key: "question" },
        { label: "점수", key: "score" },
        { label: "챕터1", key: "ch1Name" },
        { label: "챕터2", key: "ch2Name" },
        { label: "챕터3", key: "ch3Name" },
        { label: "챕터4", key: "ch4Name" },
    ]

    const headersDiagnosis = [
        { label: "번호", key: "id" },
        { label: "질문", key: "question" },
        { label: "답변", key: "answer" },
        { label: "점수", key: "score" },
        { label: "챕터1", key: "ch1Name" },
        { label: "챕터2", key: "ch2Name" },
    ]

    const classes = useStyles()
    return (
        <>
            <div>
                <CSVLink data={questionsAbility} headers={headersAbility} filename={`역량 평가 답변_${profile.email}.csv`} style={{ lineHeight: '60px', marginLeft: '40px' }}>
                    <Button style={{ margin: '0 0 30px 0', width: "160px", height: "40px", backgroundColor: "#1d76db", color: 'white' }}>역량 평가 답변 다운로드</Button>
                </CSVLink>
                <CSVLink data={diagnosis} headers={headersDiagnosis} filename={`진단 평가 답변_${profile.email}.csv`} style={{ lineHeight: '60px', marginLeft: '40px' }}>
                    <Button style={{ margin: '0 0 30px 0', width: "160px", height: "40px", backgroundColor: "#1d76db", color: 'white' }}>진단 평가 답변 다운로드</Button>
                </CSVLink>
            </div>

            <div style={{ width: '1500px', height: '600px', marginLeft: '260px' }} >

                <div>
                    <h3>역량 평가 리스트</h3>
                    <TableContainer className={classes.TableContainer}  >
                        <Table stickyHeader>
                            <TableHead className={classes.TableHeadRow} >
                                <TableRow  >
                                    <TableCell className={classes.TableHeadRow} style={{ width: '50px' }}>번호</TableCell>
                                    <TableCell className={classes.TableHeadRow} style={{ width: '700px' }}>질문</TableCell>
                                    <TableCell className={classes.TableHeadRow} style={{ width: '100px' }}>점수</TableCell>
                                    <TableCell className={classes.TableHeadRow} style={{ width: '100px' }}>챕터1</TableCell>
                                    <TableCell className={classes.TableHeadRow} style={{ width: '100px' }}>챕터2</TableCell>
                                    <TableCell className={classes.TableHeadRow} style={{ width: '100px' }}>챕터3</TableCell>
                                    <TableCell className={classes.TableHeadRow} style={{ width: '150px' }}>챕터4</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {questionsAbility && questionsAbility.length > 0 ? questionsAbility.map((items) =>

                                    <TableRow>
                                        <TableCell>{items.id}</TableCell>
                                        <TableCell>{items.question}</TableCell>
                                        <TableCell>{items.score}</TableCell>
                                        <TableCell>{items.ch1Name}</TableCell>
                                        <TableCell>{items.ch2Name}</TableCell>
                                        <TableCell>{items.ch3Name}</TableCell>
                                        <TableCell>{items.ch4Name}</TableCell>
                                    </TableRow>
                                ) : ""}
                            </TableBody>

                        </Table>
                    </TableContainer>

                    <h3>진단 평가 리스트</h3>
                    <TableContainer className={classes.TableContainer}  >
                        <Table stickyHeader>
                            <TableHead className={classes.TableHeadRow} >
                                <TableRow  >
                                    <TableCell className={classes.TableHeadRow} style={{ width: '50px' }}>번호</TableCell>
                                    <TableCell className={classes.TableHeadRow} style={{ width: '700px' }}>질문</TableCell>
                                    <TableCell className={classes.TableHeadRow} style={{ width: '100px' }}>답변</TableCell>
                                    <TableCell className={classes.TableHeadRow} style={{ width: '100px' }}>점수</TableCell>
                                    <TableCell className={classes.TableHeadRow} style={{ width: '100px' }}>챕터1</TableCell>
                                    <TableCell className={classes.TableHeadRow} style={{ width: '100px' }}>챕터2</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {diagnosis && diagnosis.length > 0 ? diagnosis.map((items) =>

                                    <TableRow>
                                        <TableCell>{items.id}</TableCell>
                                        <TableCell>{items.question}</TableCell>
                                        <TableCell>{items.answer}</TableCell>
                                        <TableCell>{items.score}</TableCell>
                                        <TableCell>{items.ch1Name}</TableCell>
                                        <TableCell>{items.ch2Name}</TableCell>
                                    </TableRow>
                                ) : ""}
                            </TableBody>

                        </Table>
                    </TableContainer>
                </div>

            </div>

        </>
    )

}
