import { TopToolbar, ListButton } from "react-admin" 
import React from "react" 

const EditActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
) 

export default EditActions 
