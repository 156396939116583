import { withStyles } from "@material-ui/core/styles"
import React from "react"
import {
  TopToolbar,
  Datagrid,
  DateField,
  List,
  TextField,
  BooleanField,
} from "react-admin"

const styles = (theme) => ({
  title: {
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
})

const Actions = ({ basePath }) => (
  <TopToolbar>
  </TopToolbar>
)

const WaitUsersList = withStyles(styles)(({ classes, ...props }) => {
  return (
    <List
      {...props}
      actions={<Actions />}
      perPage={25}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false} />
        <TextField source="email" sortable={false} />
        <TextField source="career" sortable={false} />
        <TextField source="jobName" sortable={false} />
        <TextField source="purpose" sortable={false} />
        <BooleanField source="isSwitch" sortable={false} />
        <BooleanField source="isSwitchJob" sortable={false} />
        <BooleanField source="isNotification" sortable={false} />
        <BooleanField source="isMarketing" sortable={false} />
        <TextField source="status" sortable={false} />
        <DateField source="createdAt" locales="ko" showTime sortable={false} />
      </Datagrid>
    </List>
  )
})

export default WaitUsersList 
