import React from "react"
import { BooleanField, DateField, ImageField, Show, SimpleShowLayout, TextField, UrlField } from "react-admin"
import { ShowAction } from "../../components/actions"

const ContentsShow = (props) => {
  return (
    <Show {...props} actions={<ShowAction record={props} />}>
      <SimpleShowLayout>
        <TextField source="type" sortable={false} />
        <TextField source="title" sortable={false} />
        <TextField source="content" sortable={false} />
        <ImageField source="image" sortable={false} />
        <TextField source="abilityName" sortable={false} />
        <UrlField source="link" sortable={false} />
        <BooleanField source="status" sortable={false} />
        <DateField source="createdAt" locales="ko" showTime sortable={false} />
      </SimpleShowLayout>
    </Show>
  )
}

export default ContentsShow 
