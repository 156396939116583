import React from "react"
import { Create, FileInput, ImageField, SelectInput, SimpleForm, TextInput, AutocompleteInput } from "react-admin"
import { required } from "../../validators"
import CreateActions from "../../components/actions/CreateActions"
import CreateToolbar from "../../components/toolbars/CreateToolbar"

const abilities = [
  { id: 1, name: '성취지향' },
  { id: 2, name: '안정지향' },
  { id: 3, name: '재창조형' },
  { id: 4, name: '창조형' },
  { id: 5, name: '관습형' },
  { id: 6, name: '관계형' },
  { id: 7, name: '실행형' },
  { id: 8, name: '전략형' },
  { id: 9, name: '감각형' },
  { id: 10, name: '개발형' },
  { id: 11, name: '규율형' },
  { id: 12, name: '윤리형' },
  { id: 13, name: '교류형' },
  { id: 14, name: '공감형' },
  { id: 15, name: '개혁' },
  { id: 16, name: '추진(실행형)' },
  { id: 17, name: '최고지향' },
  { id: 18, name: '열정' },
  { id: 19, name: '경쟁' },
  { id: 20, name: '목표의식' },
  { id: 21, name: '성취' },
  { id: 22, name: '끈기' },
  { id: 23, name: '복구' },
  { id: 24, name: '분석' },
  { id: 25, name: '전략' },
  { id: 26, name: '문제해결' },
  { id: 27, name: '성과지향' },
  { id: 28, name: '계획성' },
  { id: 29, name: '관점(초점)' },
  { id: 30, name: '아이디어(착상,상상)' },
  { id: 31, name: '독자적(자기확신)' },
  { id: 32, name: '탐구(탐구심,관찰)' },
  { id: 33, name: '호기심(창의,관심,공상)' },
  { id: 34, name: '직관력' },
  { id: 35, name: '예술적(심미안)' },
  { id: 36, name: '감성적(센스티브)' },
  { id: 37, name: '진취적(적극성)' },
  { id: 38, name: '창조,개발' },
  { id: 39, name: '차별화(개성,독창성)' },
  { id: 40, name: '도전정신(모험,용기)' },
  { id: 41, name: '미래지향' },
  { id: 42, name: '자기개발' },
  { id: 43, name: '변화' },
  { id: 44, name: '개방성' },
  { id: 45, name: '보존' },
  { id: 46, name: '명령' },
  { id: 47, name: '인내심' },
  { id: 48, name: '관습(정통)' },
  { id: 49, name: '충성심(의리)' },
  { id: 50, name: '조직지향' },
  { id: 51, name: '판단' },
  { id: 52, name: '규범(규율,규칙,질서)' },
  { id: 53, name: '윤리' },
  { id: 54, name: '존경' },
  { id: 55, name: '공헌(희생,봉사)' },
  { id: 56, name: '책임' },
  { id: 57, name: '공정(조정,중재)' },
  { id: 58, name: '신중함' },
  { id: 59, name: '협동(협력)' },
  { id: 60, name: '사교(친화)' },
  { id: 61, name: '대인관계' },
  { id: 62, name: '의사소통' },
  { id: 63, name: '적응력' },
  { id: 64, name: '유머' },
  { id: 65, name: '조율(협상)' },
  { id: 66, name: '호감(매력)' },
  { id: 67, name: '희망(긍정)' },
  { id: 68, name: '존중' },
  { id: 69, name: '조화' },
  { id: 70, name: '친절' },
  { id: 71, name: '공감(동감)' },
  { id: 72, name: '격려(지지)' },
  { id: 73, name: '진솔' },
  { id: 74, name: '배려' }
]

const ContentsCreate = (props) => (
  <Create {...props} actions={<CreateActions />}>
    <SimpleForm toolbar={<CreateToolbar />}>
      <TextInput source="title" validate={required} />
      <TextInput source="content" validate={required} />
      <SelectInput label='타입' defaultValue={0}
        style={{ width: '200px' }}
        source="type"
        validate={required}
        choices={[
          { id: 'article', name: "기사" },
          { id: 'toolkit', name: "툴킷" },
          { id: 'vod', name: "vod" },
        ]} />
      <AutocompleteInput
        label="분류"
        style={{ width: '200px' }}
        source='abilityId'
        validate={required}
        choices={abilities} />
      <FileInput source="image" src="img">
        <ImageField source="image" validate={required} />
      </FileInput>
      <TextInput source="link" validate={required} />
      <SelectInput label='상태' defaultValue={0} style={{ width: '200px' }} source="status" validate={required} choices={[
        { id: 1, name: "공개" },
        { id: 0, name: "비공개" },
      ]} />

    </SimpleForm>
  </Create>
)

export default ContentsCreate
