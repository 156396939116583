import VerifiedUserOutlined from "@material-ui/icons/VerifiedUserOutlined"
import CompUserInfo from "./CompUserInfo"
import CompUsersList from "./CompUsersList"

const CompUsers = {
  list: CompUsersList,
  show: CompUserInfo,
  icon: VerifiedUserOutlined,
}

export default CompUsers 
