import React from 'react'
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    profile: {
        minWidth: 650,
    },
    TableContainer: {
        width: '350px',
        border: "1px solid gray",
        height: '500px',
        overflow: 'auto'
    },

    TableHeadRow: {
        borderBottom: "2px solid gray"
    },

})


export default function NameCard({
    userId,
    profile,
    diagnosisScore,
    abilityScore,
    result,
    downloadFile,

}) {

    const classes = useStyles()
    return (
        <>
            <div style={{ width: '2000px', height: '600px', }} >
                <div style={{ float: "left", margin: "15px" }} >
                    <Button onClick={() => downloadFile(profile.result, profile.email)} style={{ margin: '0 0 30px 0', width: "100px", height: "40px", color: "white", backgroundColor: "#1d76db", }}> 결과 다운로드 </Button>
                    <h3>Email</h3>
                    <div>{profile.email}</div>
                    <h3>직무</h3>
                    <div>{profile.jobName}</div>
                    <h3>목적</h3>
                    <div>{profile.purpose}</div>
                    <h3>상태</h3>
                    <div>{profile.status}</div>
                    <h3>커리어</h3>
                    <div>{profile.career}</div>

                </div>
                <div style={{ float: "right" }} >
                    <h3>결과</h3>
                    <TableContainer className={classes.TableContainer} >
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.TableHeadRow} style={{ width: '200px', variant: 'head' }} >항목</TableCell>
                                    <TableCell className={classes.TableHeadRow} style={{ width: '100px' }}>점수</TableCell>
                                    <TableCell className={classes.TableHeadRow} style={{ width: '100px' }}>비율</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {result && result.length > 0 ? result.map((items) =>
                                    <TableRow>
                                        <TableCell>{items.name}</TableCell>
                                        <TableCell>{items.score}</TableCell>
                                        <TableCell>{items.percentage}%</TableCell>
                                    </TableRow>
                                ) : ""}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>

                <div style={{ float: "right" }} >
                    <h3>chapter4</h3>
                    <TableContainer className={classes.TableContainer} >
                        <Table stickyHeader>
                            <TableHead className={classes.TableHeadRow} >
                                <TableRow  >
                                    <TableCell className={classes.TableHeadRow} style={{ width: '200px', variant: 'head' }} >항목</TableCell>
                                    <TableCell className={classes.TableHeadRow} style={{ width: '100px' }}>점수</TableCell>
                                    <TableCell className={classes.TableHeadRow} style={{ width: '100px' }}>비율</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {abilityScore && abilityScore.length > 0 ? abilityScore.map((items, i) =>

                                    items.count < 14 ?
                                        <TableRow>
                                            <TableCell>{items.name}</TableCell>
                                            <TableCell>{items.score}</TableCell>
                                            <TableCell >{items.percent}%</TableCell>
                                        </TableRow> : ""
                                ) : ""}

                            </TableBody>

                        </Table>
                    </TableContainer>
                </div>


                <div style={{ float: "right" }} >
                    <h3>chapter3</h3>
                    <TableContainer className={classes.TableContainer}  >
                        <Table stickyHeader>
                            <TableHead className={classes.TableHeadRow} >
                                <TableRow  >
                                    <TableCell className={classes.TableHeadRow} style={{ width: '150px', variant: 'head' }} >항목</TableCell>
                                    <TableCell className={classes.TableHeadRow} style={{ width: '100px' }}>점수</TableCell>
                                    <TableCell className={classes.TableHeadRow} style={{ width: '100px' }}>비율</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {abilityScore && abilityScore.length > 0 ? abilityScore.map((items) =>

                                    items.count >= 14 && items.count < 30 ?
                                        <TableRow>
                                            <TableCell>{items.name}</TableCell>
                                            <TableCell>{items.score}</TableCell>
                                            <TableCell>{items.percent}%</TableCell>
                                        </TableRow> : ""


                                ) : ""}
                            </TableBody>

                        </Table>
                    </TableContainer>
                </div>




                <div style={{ float: "right" }} >
                    <h3>chapter2</h3>
                    <TableContainer className={classes.TableContainer} >
                        <Table stickyHeader>
                            <TableHead className={classes.TableHeadRow} >
                                <TableRow  >
                                    <TableCell className={classes.TableHeadRow} style={{ width: '150px', variant: 'head' }} >항목</TableCell>
                                    <TableCell className={classes.TableHeadRow} style={{ width: '100px' }}>점수</TableCell>
                                    <TableCell className={classes.TableHeadRow} style={{ width: '100px' }}>비율</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {abilityScore && abilityScore.length > 0 ? abilityScore.map((items) =>

                                    items.count >= 30 && items.count < 60 ?
                                        <TableRow>
                                            <TableCell>{items.name}</TableCell>
                                            <TableCell>{items.score}</TableCell>
                                            <TableCell >{items.percent}%</TableCell>
                                        </TableRow> : ""


                                ) : ""}
                            </TableBody>

                        </Table>
                    </TableContainer>
                </div>


                <div style={{ float: "right" }} >
                    <h3>chapter1</h3>
                    <TableContainer className={classes.TableContainer} >
                        <Table stickyHeader>
                            <TableHead className={classes.TableHeadRow} >
                                <TableRow  >
                                    <TableCell className={classes.TableHeadRow} style={{ width: '150px', variant: 'head' }} >항목</TableCell>
                                    <TableCell className={classes.TableHeadRow} style={{ width: '100px' }}>점수</TableCell>
                                    <TableCell className={classes.TableHeadRow} style={{ width: '100px' }}>비율</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {abilityScore && abilityScore.length > 0 ? abilityScore.map((items) =>

                                    items.count >= 60 ?
                                        <TableRow>
                                            <TableCell>{items.name}</TableCell>
                                            <TableCell>{items.score}</TableCell>
                                            <TableCell >{items.percent}%</TableCell>
                                        </TableRow> : ""


                                ) : ""}
                            </TableBody>

                        </Table>
                    </TableContainer>
                </div>

            </div>

        </>
    )

}
