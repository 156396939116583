import PersonPinCircle from "@material-ui/icons/PersonPinCircle"
import WaitUserInfo from "./WaitUserInfo"
import WaitUsersList from "./WaitUsersList"

const WaitUsers = {
  list: WaitUsersList,
  show: WaitUserInfo,
  icon: PersonPinCircle,
}

export default WaitUsers 
