import React from "react"
import moment from 'moment-timezone'
import { dataProvider } from "../../App"
import NameCard from "./NameCard"
import QuestionList from "./QuestionList"
import { Dialog, DialogTitle, } from "@material-ui/core"

moment().tz("Asia/Seoul").format()

class WaitUserInfo extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      userId: "",
      abilityId: "",
      score: "",
      percentage: "",
      profile: {},
      diagnosisScore: [],
      abilityScore: [],
      questionsAbility: [],
      questionDiagnosis: [],
      chapt1Select: '1',
      chapt2Select: '3',
      chapt3Select: '7',
      chapt4_1Select: '15',
      chapt4_2Select: '16',
      abilityList: [],
      open: false,

    }
    this.getWaitUserInfo = this.getWaitUserInfo.bind(this)
    this.diagnosisButtonClick = this.diagnosisButtonClick.bind(this)
    this.handleChangeChpat1Select = this.handleChangeChpat1Select.bind(this)
    this.handleChangeChpat2Select = this.handleChangeChpat2Select.bind(this)
    this.handleChangeChpat3Select = this.handleChangeChpat3Select.bind(this)
    this.handleChangeChpat4Select = this.handleChangeChpat4Select.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleChangeChpat1Select = (e) => {

    this.setState({
      chapt1Select: e.target.value
    })
  }

  handleChangeChpat2Select = (e) => {


    this.setState({
      chapt2Select: e.target.value
    })
  }

  handleChangeChpat3Select = (e) => {
    this.setState({
      chapt3Select: e.target.value
    })
  }

  handleChangeChpat4Select = (e) => {

    if (e.target.value === this.state.chapt4_1Select) {
      this.setState({
        chapt4_1Select: null,
      })
    } else if (e.target.value === this.state.chapt4_2Select) {
      this.setState({
        chapt4_2Select: null,
      })
    } else if (this.state.chapt4_1Select === null) {
      this.setState({
        chapt4_1Select: e.target.value,
      })
    }
    else if (this.state.chapt4_2Select === null) {
      this.setState({
        chapt4_2Select: e.target.value,
      })
    } else {
      this.setState({
        chapt4_2Select: this.state.chapt4_1Select,
        chapt4_1Select: e.target.value,
      })
    }

  }

  handleOpen() {
    this.setState({
      open: true,
    })
  }

  handleClose() {
    this.setState({
      open: false,
    })
  }

  async getWaitUserInfo() {
    try {
      const selectId = this.props.location.pathname.split('/')[2]
      const { data } = await dataProvider('GET_ONE', 'waitUsers', { id: selectId })

      if (data) {
        if (data.profile.career === "ready") data.profile.career = '취업준비생'
        else if (data.profile.career === 'junior') data.profile.career = '신입(1~3년)'
        else if (data.profile.career === 'senior') data.profile.career = '경력(3~5년)'
        else if (data.profile.career === 'highSenior') data.profile.career = '경력(5년이상'
        else if (data.profile.career === 'leader') data.profile.career = '팀장(리더) 직급자'
        else if (data.profile.career === 'ceo') data.profile.career = '대표'

        if (data.profile.status === 'ready') data.profile.status = '진단 진행 전'
        else if (data.profile.status === 'wait') data.profile.status = '진단 결과 대기 중'
        else if (data.profile.status === 'progress') data.profile.status = '진단 진행 중'
        else if (data.profile.status === 'complete') data.profile.status = '진단 완료'
      }

      this.setState({
        userId: data.id,
        profile: data.profile,
        diagnosisScore: data.diagnosisScore,
        abilityScore: data.abilityScore,
        questionsAbility: data.questionsAbility,
        questionDiagnosis: data.questionDiagnosis,
      })

    } catch (e) {
      console.log(e)
    }
  }

  async diagnosisButtonClick() {

    try {
      const answer = window.confirm("진단 결과를 제출하시겠습니까?")
      if (answer) {
        if (!this.state.chapt4_2Select || !this.state.chapt4_1Select) {
          alert("선택 데이터를 확인해주세요!")
        } else {
          this.handleOpen()
          const abilityList = [
            {
              userId: this.state.userId,
              abilityId: this.state.chapt1Select,
              score: this.state.abilityScore[this.state.chapt1Select - 1].score,
              percentage: this.state.abilityScore[this.state.chapt1Select - 1].percent,
            },
            {
              userId: this.state.userId,
              abilityId: this.state.chapt2Select,
              score: this.state.abilityScore[this.state.chapt2Select - 1].score,
              percentage: this.state.abilityScore[this.state.chapt2Select - 1].percent,
            },
            {
              userId: this.state.userId,
              abilityId: this.state.chapt3Select,
              score: this.state.abilityScore[this.state.chapt3Select - 1].score,
              percentage: this.state.abilityScore[this.state.chapt3Select - 1].percent,
            },
            {
              userId: this.state.userId,
              abilityId: this.state.chapt4_1Select,
              score: this.state.abilityScore[this.state.chapt4_1Select - 1].score,
              percentage: this.state.abilityScore[this.state.chapt4_1Select - 1].percent,
            },
            {
              userId: this.state.userId,
              abilityId: this.state.chapt4_2Select,
              score: this.state.abilityScore[this.state.chapt4_2Select - 1].score,
              percentage: this.state.abilityScore[this.state.chapt4_2Select - 1].percent,
            },
          ]

          const { data } = await dataProvider('REPORT', 'waitUsers', { id: this.state.userId, data: abilityList })

          if (data.status === 200) {

            this.handleClose()

            const answer = window.confirm("진단이 완료 되었습니다. 완료 페이지로 이동 하시겠습니까?")
            let url = ""

            if (answer) {

              url = window.location.origin + `/compUsers/${data.id}/show`
              window.location.replace(url)

            } else {

              url = window.location.origin + `/waitUsers`
              window.location.replace(url)

            }
          } else {
            this.handleClose()
            alert('다시 시도해주세요.')
          }
        }
      }


    } catch (err) {
      throw (err)
    }
  }



  componentDidMount() {
    this.getWaitUserInfo().then()
  }

  render() {
    const {
      userId,
      profile,
      diagnosisScore,
      abilityScore,
      chapt1Select,
      chapt2Select,
      chapt3Select,
      chapt4_1Select,
      chapt4_2Select,
      abilityList,
      questionsAbility,
      questionDiagnosis,
    } = this.state

    return (
      <>

        <div>
          <Dialog open={this.state.open} onClose={this.handleClose}>
            <DialogTitle>결과 제출중...</DialogTitle>
          </Dialog>
        </div>

        <div style={{ width: '1300px', padding: '20px' }}>
          <NameCard userId={userId}
            profile={profile}
            diagnosisScore={diagnosisScore}
            abilityScore={abilityScore}
            chapt1Select={chapt1Select}
            chapt2Select={chapt2Select}
            chapt3Select={chapt3Select}
            chapt4_1Select={chapt4_1Select}
            chapt4_2Select={chapt4_2Select}
            abilityList={abilityList}
            handleChangeChpat1Select={this.handleChangeChpat1Select}
            handleChangeChpat2Select={this.handleChangeChpat2Select}
            handleChangeChpat3Select={this.handleChangeChpat3Select}
            handleChangeChpat4Select={this.handleChangeChpat4Select}
            diagnosisButtonClick={this.diagnosisButtonClick}
          />
        </div>


        <div>
          <QuestionList questionsAbility={questionsAbility} questionDiagnosis={questionDiagnosis} profile={profile} />
        </div>



      </>
    )
  }


}
export default WaitUserInfo

