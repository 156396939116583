import axios from "axios"

const models = {
  auth: require("./auth"),
  admins: require("./admins"),
  users: require("./users"),
  waitUsers: require("./waitUsers"),
  compUsers: require("./compUsers"),
  contents: require("./contents"),
  result: require("./result"),
  files: require("./files")
}

async function refreshToken(apiUrl) {
  try {
    const accessToken = localStorage.getItem("accessToken")
    const refreshToken = localStorage.getItem("refreshToken")
    if (accessToken && refreshToken) {
      const options = models.auth.request(apiUrl, "REFRESH", {
        data: { accessToken, refreshToken },
      })
      const { data } = await axios(options)
      return data.accessToken
    }
  } catch (e) {
    throw e
  }
}

const httpHandler = (apiUrl) => {
  const httpClient = (options = {}) => {
    if (!options.headers) options.headers = {}
    const token = localStorage.getItem("accessToken")
    if (token) options.headers.authorization = `Bearer ${token}`
    return axios(options)
  }

  return async function (type, resource, params) {
    const resourceModel = models[resource]
    const options = await resourceModel.request(apiUrl, type, params)
    try {
      const response = await httpClient(options)
      return resourceModel.response(response, type, params)
    } catch (e) {
      if (e && e.response && e.response.status === 401) {
        try {
          const accessToken = await refreshToken(apiUrl, e)
          if (accessToken) {
            localStorage.setItem("accessToken", accessToken)
            const response = await httpClient(options)

            return resourceModel.response(response, type, params)
          }
        } catch (e) {
          throw e.response
        }
      }
      throw e.response
    }
  }
}

export default httpHandler
