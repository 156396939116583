import { CREATE, GET_ONE, GET_LIST, DELETE, UPDATE } from "react-admin"
import { uploadToS3 } from "./fileHandler"

export async function request(apiUrl, type, params) {
  let url = apiUrl + "/contents"
  let options = {}
  switch (type) {
    case CREATE:
      options.method = "POST"
      await uploadToS3(params.data, 'image', 'image', null)
      options.data = params.data
      break
    case GET_ONE:
      url += `/${params.id}`
      break
    case GET_LIST:
      const { page, perPage } = params.pagination
      const { field, order } = params.sort
      options.params = {
        ...params.filter,
        sort: field,
        order: order,
        start: (page - 1) * perPage,
        perPage: perPage,
      }
      break
    case UPDATE:
      url += `/${params.id}`
      options.method = "PUT"
      await uploadToS3(params.data, 'image', 'image', null)
      options.data = params.data
      break
    case DELETE:
      url += `/${params.id}`
      options.method = "DELETE"
      break
    default:
      break
  }
  return { url, ...options }
}

export function response(res, type, params) {
  let ret = {}
  const { data, status } = res
  switch (type) {
    case GET_LIST:

      if (data) {
        for (let i = 0; i < data.data.length; i++) {
          data.data[i].image = convertImagePath(data.data[i].image)
        }

        ret = data
      }

      break

    case GET_ONE:
      if (data) {
        data.image = convertImagePath(data.image)
        ret = { data }
      }
      break

    case UPDATE:
      if (status === 200) ret = { data: { id: params.id } }
      break

    case DELETE:
      if (status === 204) ret = { data: { id: params.id } }
      break
    default:
      ret = { data }
      break
  }
  return ret
}

export const convertImagePath = (path) => {
  return `${process.env.REACT_APP_FILE_URL}/${encodeURIComponent(path)}`
}