import { withStyles } from "@material-ui/core/styles"
import React from "react"
import {
  TopToolbar,
  CreateButton,
  Datagrid,
  DateField,
  List,
  TextField,
  BooleanField,
  ImageField,
} from "react-admin"

const styles = (theme) => ({
  title: {
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
})

const Actions = ({ basePath }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} />
  </TopToolbar>
)

const ContentsList = withStyles(styles)(({ classes, ...props }) => {
  return (
    <List
      {...props}
      actions={<Actions />}
      perPage={25}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="show">
        <TextField source="type" sortable={false} />
        <TextField source="title" sortable={false} />
        <TextField source="content" sortable={false} />
        <TextField source="abilityName" sortable={false} />
        <ImageField source="image" sortable={false} />
        <TextField source="link" sortable={false} />
        <BooleanField source="status" sortable={false} />
        <DateField source="createdAt" locales="ko" showTime sortable={false} />
      </Datagrid>
    </List>
  )
})

export default ContentsList 
