export const menus = [
  {
    title: "유저 관리",
    items: ["users"],
    names: ["유저 관리"],
    roles: [[]],
  },
  {
    title: "진단 관리",
    items: ["waitUsers", "compUsers"],
    names: ["진단 대기중", "진단 완료"],
    roles: [[]],
  },

  {
    title: "컨텐츠 관리",
    items: ["contents"],
    names: ["컨텐츠 관리"],
    roles: [[]],
  },

  {
    title: "관리자 관리",
    items: ["admins"],
    names: ["관리자 관리"],
    roles: [[]],
  },


] 
